import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"

const ErrorPage = () => (
  <Layout>
    <SEO
      title="Сторінку не знайдено"
      description="Помилка №404 Сторінку не знайдено."
      image=""
      url="/404/"
      noindex="noindex"
    />
    <div className="hero-background-light">
      <Container className="py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Newtelecom</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Сторінка 404
            </li>
          </ol>
        </nav>
        <h1 className="gradient-text">Сторінку не знайдено (404)</h1>
        <div className="hero-slogan py-4">
          <p>
            Сторінку, яку ви намагалися відкрити не знайдено.
            <br />
            Код помилки - 😞404.
          </p>
          <Button as={Link} to="/" variant="danger" size="lg" replace>
            На головну
          </Button>
        </div>
      </Container>
    </div>
    <section className="404 container py-5">
      <div className="pt-3 pb-2 h2">Що можна зробити далі?</div>
      <ol>
        <li>Спробуйте знайти посилання на сторінку в меню або футері сайту</li>
        <li>
          Перейдіть на{" "}
          <Link to="/" replace>
            головну сторінку
          </Link>{" "}
          і продовжіть роботу з сайтом.
        </li>
      </ol>
    </section>
  </Layout>
)

export default ErrorPage
